export default function useDisableScroll() {
  let body = '' as HTMLElement;
  let scrollPosition = 0;

  onMounted(() => {
    body = document.querySelector('body');
  });

  const enableScroll = () => {
    document.body.classList.remove('noscroll');
    if (body && body.style) {
      body?.style.removeProperty('overflow');
      body?.style.removeProperty('position');
      body?.style.removeProperty('top');
      body?.style.removeProperty('width');
    }
    window.scrollTo(0, scrollPosition);
  };

  const disableScroll = () => {
    scrollPosition = window.pageYOffset;
    if (body) {
      document.body.classList.add('noscroll');
      body.style.overflow = 'hidden';
      body.style.position = 'fixed';
      body.style.top = `-${scrollPosition}px`;
      body.style.width = '100%';
    }
  };
  
  return {
    disableScroll,
    enableScroll,
  };
}
